import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

import {
  WaveDividerFour,
  Content,
  Section,
  FeatureDuo,
  Text,
  Headline,
  Subline,
  Visualization,
} from "../SubComponents";

const TestimonialLeft = styled.div`
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletLandscape`
    margin-bottom: 7em;
    padding-right: 4em;
  `};
`;
const TestimonialRight = styled.div`
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletLandscape`
    margin-bottom: 7em;
    padding-left: 4em;
  `};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const lightColor = ({ theme }) => theme.backgroundColors.lightColor;

const TestimonialContent = styled(Content)`
  ${({ theme }) => theme.media.tabletLandscape`
		max-width: 65%;
	`};
  ${({ theme }) => theme.media.desktop`
		max-width: 55%;
	`};
  ${({ theme }) => theme.media.desktopPlus`
		max-width: 45%;
	`};
`;

const SectionTestimonials = () => {
  return (
    <Section id={"testimonials"} gapWidth={4} bgColor={lightColor}>
      <TestimonialContent>
        <FeatureDuo verticalOnly spaceBottom noGap>
          <Text>
            <Headline large>
              <Trans>Client Testimonials</Trans>
            </Headline>
          </Text>
          <Visualization>
            <TestimonialLeft>
              <Subline quote>
                <Trans>
                  I have worked as a voiceover director on several high profile
                  projects where Sarah was booked as the voiceover artist. She
                  has always been wonderful in every way - high professional
                  standard throughout, great delivery, a broad vocal range and
                  always super friendly. Every time, the clients were more than
                  happy with the result. Sarah is an absolute pleasure to work
                  with and I recommend her without hesitation.
                </Trans>
              </Subline>
              <Text quote>
                <Trans>Branko Tomovic</Trans>
              </Text>
            </TestimonialLeft>
            <TestimonialRight>
              <Subline quote>
                <Trans>
                  The session went swimmingly. Sarah is great! Sarah is very
                  good at taking directions and very professional in the
                  session. On top of that, her home studio quality is superb. We
                  would love to work with her again in the future if the project
                  fits.
                </Trans>
              </Subline>
              <Text quote>
                <Trans>The team at Deloitte</Trans>
              </Text>
            </TestimonialRight>
            <TestimonialLeft>
              <Subline quote>
                <Trans>
                  Sarah delivered a voiceover with exceptional professionalism,
                  high sound quality, and great attention to detail. Her quick
                  responsiveness and deep understanding made working with her a
                  breeze.
                </Trans>
              </Subline>
              <Text quote>
                <Trans>The team at ManaMotion</Trans>
              </Text>
            </TestimonialLeft>
            <TestimonialRight>
              <Subline quote>
                <Trans>
                  Sarah is a true professional, her voice acting abilities are
                  superb, the quality of the delivered recordings is high end. I
                  would definitely book her for future projects and so should
                  you.
                </Trans>
              </Subline>
              <Text quote>
                <Trans>Amir, Requination</Trans>
              </Text>
            </TestimonialRight>
          </Visualization>
        </FeatureDuo>
      </TestimonialContent>
      <WaveDividerFour bgColor="bright" />
    </Section>
  );
};

export default SectionTestimonials;
