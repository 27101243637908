import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/core/Seo";
import BaseLayout from "../components/core/BaseLayout";
import SectionHero from "../components/PageBlocks/SectionHero";
import SectionVoice from "../components/PageBlocks/SectionVoice";
import SectionClients from "../components/PageBlocks/SectionClients";
import SectionWork from "../components/PageBlocks/SectionWork";
import SectionTestimonials from "../components/PageBlocks/SectionTestimonials";
import SectionRatesCTA from "../components/PageBlocks/SectionRatesCTA";

const IndexPage = ({ location: { pathname } }) => {
  return (
    <BaseLayout>
      <SEO path={pathname} />
      <SectionHero />
      <SectionVoice />
      <SectionClients />
      <SectionWork />
      <SectionTestimonials />
      <SectionRatesCTA />
    </BaseLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
