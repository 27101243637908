import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { useInView } from "react-intersection-observer";
// import YouTube from "react-youtube";
import { ReactYouTubeLite } from "react-youtube-lite";
import styled from "styled-components";
import {
  Content,
  FeatureDuo,
  H3,
  Headline,
  PlayListLinkExternal,
  Section,
  Text,
  Visualization,
  WaveDividerThree,
  WavyLineDivider,
} from "../SubComponents";
import {
  YOUTUBE_LINKS,
  YOUTUBE_LINKS_ENGLISH,
  YOUTUBE_LINKS_GERMAN,
} from "../../constants/linkdata";
import DeSVG from "../../assets/images/svg/lang-de.svg";
import EnSVG from "../../assets/images/svg/lang-en.svg";

const VideoWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  ${({ theme }) => theme.media.tabletLandscape`
    grid-template-columns: repeat(2, 1fr);
  `};
  gap: 1.5em;
  ${({ theme }) => theme.media.tabletLandscape`
    gap: 3em;
  `};

  &:nth-of-type(1) {
    margin-bottom: 2em;
    ${({ theme }) => theme.media.tabletLandscape`
      margin-bottom: 4em;
    `};
  }

  .videoplayer {
    overflow: hidden;
    position: relative;
    width: 100%;
    contain: content;
    background-position: center center;
    background-size: cover;
    border-radius: 1em;

    &:hover {
      cursor: pointer;

      .playbutton {
        background-color: rgb(217, 46, 46);
        opacity: 1;
      }
    }

    .playbutton {
      width: 5.75em;
      height: 4em;
      background-color: #212121;
      z-index: 1;
      opacity: 0.8;
      border-radius: 1.25em;
      transition: all 0.35s cubic-bezier(0.45, 0.45, 0.2, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);

      &:before {
        content: "";
        border-style: solid;
        border-width: 0.75em 0 0.75em 1.25em;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-40%, -50%, 0);
      }
    }

    &.lyt-activated {
      cursor: unset;

      &:before,
      .playbutton {
        opacity: 0;
        pointer-events: none;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1em;
    }
  }
`;

const PlaylistLink = styled.div`
  text-decoration: underline;
  text-decoration-thickness: 0.15em;
`;

const EnglishSection = styled.div`
  background-color: ${({ theme }) => theme.backgroundColors.lighterColor};
  margin: 0 -50vw;
  padding: 2em 50vw 0;
  position: relative;
`;

const BottomBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  background-color: ${({ theme }) => theme.backgroundColors.lighterColor};
  z-index: 0;
`;

const VideoPlayerContainer = React.memo(({ videoList, videoTitles }) => {
  const { t } = useTranslation();
  const { MAIN, PLAYER_NAME } = YOUTUBE_LINKS;
  const videos = videoList.map((videoId, videoIndex) => (
    <ReactYouTubeLite
      key={videoId}
      url={`${MAIN}${videoId}`}
      noCookie={true}
      adNetwork={false}
      poster={"sddefault"}
      wrapperClass={"videoplayer"}
      playerClass={"playbutton"}
      title={t(videoTitles[videoIndex])}
      playButtonAriaLabel={t(videoTitles[videoIndex])}
      playContainerAriaLabel={t(PLAYER_NAME)}
    />
  ));
  if (videos.length < 4) {
    videos.push(
      <PlayListLinkExternal
        key="4"
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/playlist?list=PLfajVvfVcac0tlwWMdqfI9sy1LDb8rJXo"
      >
        <PlaylistLink>
          <Trans>More Videos ...</Trans>
        </PlaylistLink>
      </PlayListLinkExternal>,
    );
  }
  return videos;
});

const darkColor = ({ theme }) => theme.backgroundColors.darkColor;

const SectionWork = () => {
  const { ref: germanRef, inView: germanInView } = useInView({
    triggerOnce: true,
    rootMargin: "400px 0px",
  });
  const { ref: englishRef, inView: englishInView } = useInView({
    triggerOnce: true,
    rootMargin: "400px 0px",
  });

  return (
    <Section gapWidth={3} id={"work"} bgColor={darkColor}>
      <BottomBackground />
      <Content noSpaceBottom>
        <FeatureDuo verticalOnly spaceBottom noGap columnRatio={[5, 1]}>
          <Text bright>
            <Headline large>
              <Trans>I voiced that!</Trans>
            </Headline>
          </Text>
          <Visualization>
            <H3 flex bright>
              <DeSVG /> <Trans>German</Trans>
            </H3>
            <VideoWrapper ref={germanRef} data-inview={germanInView}>
              {germanInView ? (
                <>
                  <VideoPlayerContainer
                    videoList={YOUTUBE_LINKS_GERMAN.VIDEO_LIST}
                    videoTitles={YOUTUBE_LINKS_GERMAN.VIDEO_TITLES}
                  />
                </>
              ) : null}
            </VideoWrapper>
            <WavyLineDivider />
            <EnglishSection>
              <H3 flex bright>
                <EnSVG />
                <Trans>English</Trans>
              </H3>
              <VideoWrapper ref={englishRef} data-inview={englishInView}>
                {englishInView ? (
                  <>
                    <VideoPlayerContainer
                      videoList={YOUTUBE_LINKS_ENGLISH.VIDEO_LIST}
                      videoTitles={YOUTUBE_LINKS_ENGLISH.VIDEO_TITLES}
                    />
                  </>
                ) : null}
              </VideoWrapper>
            </EnglishSection>
          </Visualization>
        </FeatureDuo>
      </Content>
      <WaveDividerThree />
    </Section>
  );
};

export default SectionWork;
