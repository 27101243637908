import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import styled, { keyframes } from "styled-components";
import useInViewFallback from "../../util/intersectionFallback";

import HeroSVG from "../../assets/images/svg/heroimage2.svg";
import {
  Content,
  Section,
  FeatureDuo,
  Visualization,
  Text,
  Headline,
  Subline,
  WaveDividerOne,
} from "../SubComponents";

const tailAnimation = keyframes`
  50% { transform: rotate(6deg) ; }
`;
const bodyAnimation = keyframes`
  30% { transform: scaleY(1.12); }
`;

const HeroImage = styled(HeroSVG)`
  &.inView {
    .animatedTail {
      transform-origin: 300px 88px;
      animation: ${tailAnimation} ease-in-out 6s infinite;
    }
    .animationCatBody {
      transform-origin: 0 88px;
      animation: ${bodyAnimation} ease-in-out 3s infinite;
    }
  }
`;

const MobileVisualization = styled(Visualization)`
  margin-bottom: 1.8rem;
  margin-top: 1.8rem;
  ${({ theme }) => theme.media.tabletLandscape`
    display: none;
  `};
  ${({ theme }) => theme.media.phoneLandscape`
    width: 100%;
  `};
`;

const HeroVisualization = styled(Visualization)`
  display: none;
  order: 1;
  ${({ theme }) => theme.media.phonePortrait`
    order: 1;
  `};
  ${({ theme }) => theme.media.phoneLandscape`
    order: 1;
  `};
  ${({ theme }) => theme.media.tabletPortrait`
    order: 1;
  `};
  ${({ theme }) => theme.media.tabletLandscape`
    order: 1;
    display: unset;
  `};
`;

const HeroHeadline = styled(Headline)`
  margin-bottom: 0.15rem;
`;
const SectionHero = () => {
  const { ref, inView } = useInViewFallback();

  return (
    <Section id={"hero"}>
      <Content>
        <FeatureDuo>
          <HeroVisualization ref={ref}>
            <HeroImage className={inView && "inView"} />
          </HeroVisualization>
          <Text>
            <HeroHeadline hero>
              <Trans>Bilingual Voiceover Artist</Trans>
            </HeroHeadline>
            <Headline herosub>
              <Trans>bright, contemporary, engaging.</Trans>
            </Headline>
            <Subline>
              <Trans>
                Hello! I'm Sarah, I'm an experienced bilingual voice over artist
                with my own broadcast quality home studio based in London.
              </Trans>
            </Subline>
            <MobileVisualization>
              <HeroImage />
            </MobileVisualization>
            <Subline>
              <Trans>
                Within the studio I work with efficiency, great attention to
                detail and lots of positive energy.
              </Trans>
            </Subline>
            <Subline>
              <Trans>
                I'm easy to direct and always deliver work promptly, even when
                the deadline's a bit tight.
              </Trans>
            </Subline>
            <Subline>
              <Trans>
                Breathing life into words is my bread and butter! So have a look
                around, lend me your ear and maybe soon I can lend you my voice.
              </Trans>
            </Subline>
          </Text>
        </FeatureDuo>
      </Content>

      <WaveDividerOne bgColor="dark" />
    </Section>
  );
};

export default SectionHero;
