import React, { useRef, useState } from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import { RHAP_UI } from "react-h5-audio-player";
import audioDemoGermanAmerican from "../../assets/audio/Demo_German_Accent_American.mp3";
import audioDemoGermanBritish from "../../assets/audio/Demo_German_Accent_British.mp3";
import audioReelCommercialGerman from "../../assets/audio/Reel_Commercial_German_24.mp3";
import audioReelCommercialBritish from "../../assets/audio/Reel_Commercial_British.mp3";
import audioReelGamingGerman from "../../assets/audio/Reel_Gaming_German.mp3";
import DeSVG from "../../assets/images/svg/lang-de.svg";
import EnSVG from "../../assets/images/svg/lang-en.svg";

import {
  Content,
  FeatureDuo,
  Headline,
  Player as AudioDemo,
  RefLink,
  Section,
  Subline,
  Text,
  Visualization,
  WaveDividerTwo,
  H3,
} from "../SubComponents";

const audioClips = [
  {
    source: audioReelCommercialGerman,
    playerName: "Commercial Reel",
    area: "reel1",
  },
  {
    source: audioReelGamingGerman,
    playerName: "Gaming Reel",
    area: "reel2",
  },
  {
    source: audioReelCommercialBritish,
    playerName: "UK Commercial Reel",
    area: "reel3",
  },
  {
    source: audioDemoGermanAmerican,
    playerName: "US, German accented",
    area: "demo1",
  },
  {
    source: audioDemoGermanBritish,
    playerName: "UK, German accented",
    area: "demo2",
  },
];

const darkColor = ({ theme }) => theme.backgroundColors.darkColor;

const PlayerList = ({ inputList }) => {
  const { t } = useTranslation();
  const playerRefs = useRef([]);
  const [activePlayers, setActivePlayers] = useState([]);

  const logPlay = (reference) => {
    if (!activePlayers.some((instance) => instance.player === reference)) {
      setActivePlayers([...activePlayers, { player: reference }]);
    }
    activePlayers.map(function (el) {
      if (el.player !== reference) {
        el.player.pause();
      }
      return true;
    });
  };

  return (
    <>
      {inputList.map((inputElement, i) => (
        <AudioDemo
          key={i}
          ref={(element) => (playerRefs.current[i] = element)}
          autoPlay={false}
          preload="none"
          layout="horizontal-reverse"
          showJumpControls={false}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
          src={inputElement.source}
          footer={t(inputElement.playerName)}
          onPlay={() => logPlay(playerRefs.current[i].audio.current)}
          areaType={inputElement.area}
        />
      ))}
    </>
  );
};

const SectionVoice = () => (
  <Section gapWidth={1} id={"voice"} bgColor={darkColor}>
    <Content reducedTopPadding>
      <FeatureDuo verticalOnly spaceBottom reduceGap>
        <Text verticalOnly bright>
          <Headline large>
            <Trans>Hear me out...</Trans>
          </Headline>
        </Text>
        <Visualization subGrid>
          <H3 bright subGrid>
            <DeSVG />
            <Trans>German</Trans>
          </H3>
          <H3 bright subGrid>
            <EnSVG />
            <Trans>English</Trans>
          </H3>
          <PlayerList inputList={audioClips} />
        </Visualization>
        <Subline centered bright>
          {/* prettier-ignore */}
          <Trans>
            Missing something? <RefLink to="/contact">Send me your script for a free custom demo today!</RefLink>
          </Trans>
        </Subline>
      </FeatureDuo>
    </Content>
    <WaveDividerTwo bgColor="bright" />
  </Section>
);

export default SectionVoice;
